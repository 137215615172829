import * as React from "react"
import TextImageOverImage from "../components/development/text-image-over-image/TextImageOverImage"
import TextImageTop from "../components/development/text-image-top/TextImageTop"
import TextImageMiddle from "../components/development/text-image-middle/TextImageMiddle"
import CardImage from "../components/development/card-image/CardImage"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/global/contact-form/ContactForm"

const Development = () => (
  <Layout>
    <Seo title="Development NSC" />
    <div>Development page</div>
    <TextImageTop />
    <TextImageOverImage />
    <TextImageMiddle />
    <CardImage />
    <ContactForm />
  </Layout>
)

export default Development
