import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./TextImageMiddle.css"

export default function TextImageMiddle() {
  return (
    <div className="card text-image text-image--overlap">
      <div className="text-image__text text-image__text--left-over">
        <h2 className="text-image__text__h2">Custom Build</h2>
        <p className="text-image__text__p">
          If you want the full package with a professional custom design and
          your site built with code optimised for SEO and perfomance then this
          is the option for you.
        </p>
      </div>
      <StaticImage
        className="text-image__static-image text-image__static-image--right-under"
        src="../../../images/Non Stop Coding Custom Build Code Website.png"
        alt="A Laptop"
      />
    </div>
  )
}
