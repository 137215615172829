import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./TextImageTop.css"
export default function TextImage() {
  return (
    <div className="card text-image text-image--overlap">
      <div className="text-image__text text-image__text--left-over">
        <h2 className="text-image__text__h2">Web Development</h2>
        <p className="text-image__text__p">
          I can build you anything from a brochure site to a fully customised
          web application. I'll make sure it's fully tested and responsive.
        </p>
      </div>
      <StaticImage
        className="text-image__static-image text-image__static-image--right-under"
        src="../../../images/Non Stop Coding Website Development Hero.png"
        alt="A Laptop"
      />
    </div>
  )
}
