import { StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import React from "react"
import "./CardImage.css"
import CardTwoImage from "./CardTwoImage"

export default function CardImage() {
  const CardTwoImg = CardTwoImage()

  return (
    <div className="card card-image">
      <div className="card-image__card">
        <BgImage
          image={CardTwoImg}
          className="card-image__card__background-image"
        >
          <div className="card-image__card__text-container">
            <h2 className="card-image__card__text-container__h2">
              Freelance Development
            </h2>
            <p className="card-image__card__text-container__p">
              Maybe you have an application or site already up and running, and
              you're looking for a few changes. For this I provide a flat rate
              of £24.99 per hour. Contact me to discuss your requirements.
            </p>
          </div>
        </BgImage>
      </div>
      <StaticImage
        className="card-image__static-image"
        src="../../../images/Non Stop Coding Website Design Mobile Responsive Renderfolk Mockup.png"
        alt="A Laptop"
        objectFit="contain"
      />
    </div>
  )
}
