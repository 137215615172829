import React from "react"
import "./TextImageOverImage.css"
import { StaticImage } from "gatsby-plugin-image"

import { BgImage } from "gbimage-bridge"
import ImageProvider from "./ImageProvider"
import ImageProviderMobile from "./ImageProviderMobile"

function TextImageOverImage() {
  const bgImage = ImageProvider()
  const bgImageMobile = ImageProviderMobile()

  return (
    <>
      <div className="card text-image-over-image text-image-over-image--laptop">
        <BgImage
          image={bgImage}
          className="text-image-over-image__background-image"
          style={{
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="text-image-over-image__background-image__column-container">
            <StaticImage
              className="text-image-over-image__background-image__column-container__image"
              src="../../../images/Non Stop Coding CMS Website.png"
              alt="A Laptop"
            />
            <div className="text-image-over-image__background-image__column-container__text">
              <h2 className="text-image-over-image__background-image__column-container__text__h2">
                CMS Website
              </h2>

              <p className="text-image-over-image__background-image__column-container__text__p">
                The most cost effective solution is a WordPress website where
                you have a login to manage the content yourself. While not being
                as performant as a custom build, this will give you full control
                over your content without having to pay more for small changes
                to your site. If you are looking to update your content
                regularly and are on a low budget then this is for you.
              </p>
            </div>
          </div>
        </BgImage>
      </div>
      <div className="text-image-over-image text-image-over-image--mobile">
        <BgImage
          image={bgImageMobile}
          className="text-image-over-image__background-image"
          style={{
            backgroundSize: "contain",
            backgroundPosition: "50% 50%",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="text-image-over-image__background-image__column-container--mobile">
            <StaticImage
              className="text-image-over-image__background-image__column-container__image"
              src="../../../images/Non Stop Coding CMS Website.png"
              alt="A Laptop"
            />
            <div className="text-image-over-image__background-image__column-container__text">
              <h2 className="text-image-over-image__background-image__column-container__text__h2">
                CMS Website
              </h2>

              <p className="text-image-over-image__background-image__column-container__text__p">
                The most cost effective solution is a WordPress website where
                you have a login to manage the content yourself. While not being
                as performant as a custom build, this will give you full control
                over your content without having to pay more for small changes
                to your site. If you are looking to update your content
                regularly and are on a low budget then this is for you.
              </p>
            </div>
          </div>
        </BgImage>
      </div>
    </>
  )
}

export default TextImageOverImage
